&--family {
  position: relative;
  padding: 44px 20px 20px;
  background-color: $g-color-light-gray-2;
  border: 1px solid rgba($g-color-general-dark, 0.05);
  border-radius: 10px;
  text-align: center;
  margin-right: 30px;
  margin-bottom: 5px;
  cursor: pointer;
  @include h-general-transition();

  &:last-child {
    margin-right: 0;
  }

  #{$root} {
    &__title {
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 20px;
      @include h-general-transition();
    }

    &__image {
      margin: 0 auto;
      margin-bottom: 20px;
    }

    &__text {
      @include h-text-small;
      min-height: 40px;
      margin-bottom: 20px;
    }

    &__checkbox {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 10px;
      right: 10px;
      border: 1px solid rgba($g-color-general-dark, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: transparent;
      font-size: 10px;
    }
  }

  &:hover {
    background-color: $g-color-white;

    @include media-breakpoint-up(lg) {
      box-shadow: 0px 10px 60px rgba($g-color-general-dark, 0.1);
    }
  }

  &.is-active {
    border: 1px solid rgba($g-color-general-dark, 0.1);
    background-color: $g-color-white;
    box-shadow: none;

    #{$root} {
      &__checkbox {
        background-color: $g-color-primary;
        border-color: $g-color-primary;
        color: $g-color-white;
      }
    }
  }

  &.is-active,
  &:hover {
    #{$root} {
      &__title {
        color: $g-color-primary;
      }
    }
  }
}
