.l-base {
  overflow: hidden;
  min-height: calc(100vh - 60px);

  @include media-breakpoint-up(lg) {
    min-height: calc(100vh - 80px);
  }

  &.--step {
    background-color: rgba($color: $g-color-general-dark, $alpha: 0.03);
  }
}
