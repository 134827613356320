$steps: 20;

@for $i from 1 through $steps {
  .mt-#{$i * 2} {
    margin-top: $i * 2px !important;
  }
}

@for $i from 1 through $steps {
  .mr-#{$i * 2} {
    margin-right: $i * 2px !important;
  }
}

@for $i from 1 through $steps {
  .mb-#{$i * 2} {
    margin-bottom: $i * 2px !important;
  }
}

@for $i from 1 through $steps {
  .ml-#{$i * 2} {
    margin-left: $i * 2px !important;
  }
}
