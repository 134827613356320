.c-dropdown {
  position: relative;
  z-index: 1;

  &__body {
    display: block;
    max-height: 250px;
    overflow: hidden;
    overflow-y: auto;
    @include h-scrolled;
    @include h-hide-scroll;
  }
}
