.c-form {
  $root: &;

  &__row {
    margin-bottom: 16px;

    &--submit {
      margin-top: 14px;
      margin-bottom: 0;
    }
  }

  &__icon--account {
    background-image: url('../../../../assets/images/signin-user.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 60px;
    height: 60px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 30px;
    padding-top: 30px;

    &--center {
      justify-content: center;
    }
  }

  &__footer-full-row {
    width: 100%;
    margin-top: 30px;

    text-align: center;
    @include h-text-small;
  }

  &__footer-link {
    $general-blue: #0f5ef7;
    @include h-text-small;
    color: $general-blue;

    &:hover {
      color: rgba($color: $g-color-general-dark, $alpha: 1);
    }

    &--back {
      display: flex;
      align-items: center;
    }

    .--rotate {
      display: inline-block;
      margin-right: 5px;
      padding-left: 0;

      transform: rotate(180deg);
    }

    &--blue {
      color: $g-color-primary;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto 0;

    color: rgba($color: $g-color-general-dark, $alpha: 0.3);

    &--left {
      align-items: flex-start;
    }
  }

  &__text {
    margin-bottom: 14px;

    @include h-text-regular;

    &--small {
      margin-bottom: 0;

      @include h-text-xs-regular;
    }

    &.--center {
      margin-bottom: 0;

      text-align: center;
    }

    &--dark {
      color: rgba($color: $g-color-general-dark, $alpha: 0.85);
    }

    &--green-bg {
      padding: 20px;

      color: rgba($color: $g-color-general-dark, $alpha: 0.85);

      background-color: rgba($color: $g-color-green, $alpha: 0.1);
    }

    &--red-bg {
      padding: 20px;

      color: rgba($color: $g-color-general-dark, $alpha: 0.85);

      background-color: rgba($color: $g-color-red, $alpha: 0.1);
    }

    &--center {
      text-align: center;
    }
  }

  &__title {
    margin-top: 10px;
    margin-bottom: 30px;

    @include h-text-h3;

    &--dark {
      color: $g-color-general-dark;
    }
  }

  &__sign-in {
    color: #000932;
  }

  &__header {
    margin-bottom: 30px;

    #{$root} {
      &__title {
        margin-bottom: 0;
      }
    }
  }

  &__row {
    min-width: 10vw;
    width: 80%;
    max-width: 300px;

    .iti {
      display: block;
    }
  }

  @include media-breakpoint-up(md) {
    justify-content: center;

    &__copyright {
      position: absolute;
      bottom: 0px;
      left: calc(50% - 236px / 2);

      margin-bottom: 10px;
    }
  }

  @include media-breakpoint-up(lg) {
    justify-content: center;

    &__row {
      &--invite {
        width: 420px;
      }
    }

    &__copyright {
      position: static;

      margin-top: auto;
      margin-bottom: 20px;
    }
  }
  @import 'c-form-autorized';
}
