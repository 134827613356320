.c-copyright {
  margin-top: auto;
  margin-bottom: 30px;
  padding-top: 60px;

  text-align: center;
  @include h-text-xs-regular;
  color: rgba($color: $g-color-general-dark, $alpha: 0.3);

  &.--tablet {
    display: none;
  }
}

@include media-breakpoint-up(md) {
  .c-copyright {
    display: none;

    &.--tablet {
      display: block;
      padding-top: 30px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-copyright {
    display: block;
    padding: 30px;

    &.--tablet {
      display: none;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .c-copyright {
    display: none;

    &.--tablet {
      display: block;
    }
  }
}
