.c-button {
  position: relative;

  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  background: none;
  border: none;

  cursor: pointer;

  &--primary {
    padding: 12px 24px;
    border-radius: 10px;
    background-color: $g-color-primary;
    color: $g-color-white;

    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    @include h-general-transition(background-color);

    &:hover,
    &:active,
    &:focus {
      color: $g-color-white;
      background-color: $g-color-primary-hover;
    }

    &.-lined {
      padding: 8px 20px;

      @include h-text-menu;
      color: $g-color-primary;

      background: none;
      border: 2px solid $g-color-primary;

      &:hover,
      &:active,
      &:focus {
        border-color: $g-color-red-hover;
        color: $g-color-red-hover;
      }

      &.-large {
        padding: 12px 22px;
      }

      &.-medium {
        padding: 12px 22px;
      }

      &.-dark {
        border-color: $g-color-general-dark;
        color: $g-color-general-dark;

        &:hover,
        &:active,
        &:focus {
          background-color: transparent;
          color: $g-color-primary;
          border-color: $g-color-primary;
        }
      }

      &.-red {
        border-color: $g-color-red-hover;
        background-color: transparent;
        color: $g-color-red-hover;

        &:hover,
        &:active,
        &:focus {
          border-color: $g-color-primary;
          background-color: transparent;
          color: $g-color-primary;
        }
      }

      &.-light {
        border-color: $g-color-light-primary;

        &:hover {
          border-color: $g-color-primary;
        }
      }
    }
  }

  &--transparent {
    @include h-text-xs-bold;
    color: $g-color-primary;
  }

  &.is-disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.3;
    filter: grayscale(1);
  }

  &__icon {
    margin-left: auto;
    padding-left: 24px;
    font-size: 16px;

    &--small {
      font-size: 12px;
    }
  }

  &.-medium {
    @include h-text-h5;
    padding: 14px 24px;
  }

  &.-small {
    @include h-text-xs-bold;
    padding: 4px 8px;
  }

  &.-profile {
    @include h-text-button;
    padding: 7px 14px;
  }

  &.-red {
    background-color: $g-color-red;

    &:hover,
    &:active,
    &:focus {
      background-color: $g-color-red-hover;
    }
  }

  &.-full {
    width: 100%;
  }

  &.-step {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 380px;
    }
  }
}
