* {
  outline: none !important;
  padding: 0;
  margin: 0;
}

html {
  font-size: 16px;
  overflow-x: hidden;

  &.is-overlay {
    overflow: hidden;
  }
}

body {
  overflow-x: hidden;
  @include h-text-regular;
  color: $g-color-general-dark;
  -webkit-text-size-adjust: 100%;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

a,
.c-link {
  cursor: pointer;
  text-decoration: none;
  color: $g-color-primary;
  @include h-general-transition;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    color: $g-color-primary-hover;
  }
}

input,
textarea,
select {
  width: 100%;
}

img,
svg,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none !important;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  -webkit-clip-path: inset(100%);
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
