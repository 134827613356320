.c-preloader {
  position: absolute;
  top: 25%;
  left: 0;
  pointer-events: none;
  user-select: none;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 40px 0;

  .c-preloader__cube {
    z-index: 99;
    width: 50px;
    height: 58px;
    margin: auto;
    transform-origin: 50% 45%;
    animation: rotate-move 2.4s cubic-bezier(0.79, 0.02, 0.16, 1.01) forwards infinite;
  }

  .c-preloader__rhombus.-first {
    animation: rhombus-3-move 1.2s ease infinite;
    right: 15%;
    height: 0;
    width: 25px;
    height: 21px;
    transform: rotate(29deg) skewX(-32deg);
    background-color: $g-color-primary;
    top: calc(50% - 26px);
    left: calc(50% - 12px);
    position: absolute;
    border-radius: 1px;
  }

  .c-preloader__rhombus.-second {
    animation: rhombus-2-move 1.2s ease infinite;
    bottom: 0;
    right: 0;
    height: 0;
    width: 25px;
    height: 21px;
    transform: rotate(-29deg) skewX(-29deg);
    display: block;
    background-color: #0036cb;
    border-radius: 1px;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 1px);
  }

  .c-preloader__rhombus.-third {
    animation: rhombus-1-move 1.2s ease infinite;
    bottom: 0px;
    right: 0px;
    width: 25px;
    height: 21px;
    transform: rotate(-331deg) skewX(29deg);
    display: block;
    background-color: $g-color-primary;
    border-radius: 1px;
    position: absolute;
    top: calc(50% - 8px);
    left: calc(50% - 23px);
  }
}

@keyframes rhombus-3-move {
  28% {
    transform: scale(0.3) rotate(29deg) skewX(-32deg);
  }

  60% {
    transform: scale(0.3) rotate(29deg) skewX(-32deg);
  }
}

@keyframes rhombus-2-move {
  28% {
    transform: scale(0.3) rotate(-29deg) skewX(-29deg);
  }

  60% {
    transform: scale(0.3) rotate(-29deg) skewX(-29deg);
  }
}

@keyframes rhombus-1-move {
  28% {
    transform: scale(0.3) rotate(-331deg) skewX(29deg);
  }

  60% {
    transform: scale(0.3) rotate(-331deg) skewX(29deg);
  }
}

@keyframes rotate-move {
  from {
    transform: rotate(-180deg);
  }

  45% {
    transform: rotate(0deg);
  }

  65% {
    transform: rotate(0deg);
  }

  84% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
