.c-wizard {
  $root: &;

  position: relative;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 60px);
  background-color: $g-color-white;
  margin-top: 60px;

  @include media-breakpoint-up(lg) {
    justify-content: space-between;
    min-height: calc(100vh - 80px);
    margin-top: 80px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      min-width: 810px;
    }
  }

  &__form {
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 380px;
    }

    &.-groups {
      @include media-breakpoint-up(md) {
        width: 420px;
      }
    }
  }

  &__animation {
    display: none;
    position: relative;
    max-height: 855px;
    width: 100%;

    &--updating {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }

    &--man {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
    }

    @include media-breakpoint-up(xxl) {
      display: block;
    }
  }

  &__overlay {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($g-color-white, 0.5);
    }
  }

  &__steps-bar {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }
  }

  &__step-title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 38px;

    @include media-breakpoint-up(md) {
      margin-bottom: 48px;
    }

    .red {
      color: $g-color-red;
    }

    &.-tariffs {
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 20px;
    }
  }

  &__step-subtitle {
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 38px;

    @include media-breakpoint-up(md) {
      margin-bottom: 48px;
    }
  }

  &__row {
    margin-bottom: 36px;

    &--button {
      display: flex;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 40px;

      @include media-breakpoint-up(md) {
        margin-top: 40px;
      }
    }
  }

  &__footer {
    text-align: center;
    @include h-text-small;
    color: rgba($color: $g-color-general-dark, $alpha: 0.3);
    margin-top: auto;
  }

  &__user-data {
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }
  }

  &__user-item {
    display: flex;
    align-items: center;
    margin-right: 60px;
    font-family: $g-font-family-alt;
    font-weight: 800;
    font-size: 14px;
    line-height: 1.4;

    &:first-child {
      margin-bottom: 30px;
      font-size: 16px;
      line-height: 1.7;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.4;
      }
    }

    &:nth-child(2) {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
    }

    &:not(:first-child) {
      margin-left: 20px;

      @include media-breakpoint-up(md) {
        margin-left: 0;
      }
    }

    &-logo {
      width: 60px;
      height: 60px;
      margin-right: 10px;
      background-color: $g-color-white;
      border: 1px solid rgba($g-color-general-dark, 0.05);
      box-shadow: 0px 3px 10px rgba($g-color-general-dark, 0.05);
      border-radius: 10px;
      padding: 3px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__info-cards {
    padding-top: 30px;
    border-top: 2px solid rgba($g-color-general-dark, 0.1);
    margin: 0 auto;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      display: flex;
      padding-top: 60px;
      margin-bottom: 40px;
    }

    @include media-breakpoint-up(lg) {
      width: 500px;
    }
  }

  &__group {
    padding: 28px 20px 20px;
    background-color: $g-color-white;
    border: 1px solid rgba($g-color-general-dark, 0.05);
    box-shadow: 0px 5px 40px rgba($g-color-general-dark, 0.05);
    border-radius: 10px;
  }

  &__group-title {
    font-weight: bold;
    font-size: 14px;
    line-height: 1.4;
    color: rgba($g-color-general-dark, 0.2);
    text-align: center;

    &.-alt {
      font-size: 12px;
      color: rgba($g-color-general-dark, 0.85);
    }
  }

  &__templates-info-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 18px;
    font-family: $g-font-family-alt;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.4;

    .icon {
      font-size: 16px;
      margin-left: 8px;
    }
  }

  &__application-loader {
    position: fixed;
    z-index: 1500;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($g-color-light-gray, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text {
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;

      &.-red {
        color: $g-color-red;
      }
    }

    .loader {
      position: static;
      margin: 30px 0;
    }
  }

  &__finish-title {
    font-weight: 800;
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 34px;
      line-height: 1.4;
      margin-top: 0;
    }

    .red {
      color: $g-color-red;
    }
  }

  &__finish-subtitle {
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 40px;
    text-align: center;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 60px;
    }
  }
}
