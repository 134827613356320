.c-header {
  $root: &;

  z-index: 100;
  position: fixed;

  width: 100%;

  background-color: transparent;

  &__top {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-height: 80px;
    padding: 15px;

    background-color: transparent;
  }

  &__body {
    display: none;
  }

  &__back {
    margin-right: 10px;
    height: 25px;
    width: 25px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: none;
    cursor: pointer;
    @include h-general-transition(background-color);
    font-size: 10px;
    color: $g-color-general-dark;
    transform: scale(-1, 1);

    &:hover {
      background-color: $g-color-light-primary;
      color: $g-color-general-dark;

      img {
        opacity: 1;
      }
    }

    img {
      display: block;
      opacity: 0.5;
      @include h-general-transition(opacity);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
  }

  &__logo-link {
    display: flex;
  }

  &__title {
    display: none;
  }

  &__right {
    display: flex;
  }

  &__burger {
    position: relative;

    width: 20px;
    height: 20px;

    cursor: pointer;
  }

  &__button--burger {
    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 2px;

    background-color: $g-color-general-dark;

    &::before {
      content: '';

      position: absolute;
      top: 18px;
      left: 0;

      width: 20px;
      height: 2px;

      background-color: $g-color-general-dark;
    }

    &::after {
      content: '';

      position: absolute;
      top: 9px;
      right: 0;

      width: 16px;
      height: 2px;

      background-color: $g-color-red;
    }
  }

  &__buttons {
    display: none;
  }

  &__link {
    margin-right: 30px;

    @include h-text-menu;
    font-weight: 700;
    color: $g-color-general-dark;
  }

  &__user {
    display: none;
  }

  &.is-open {
    height: 100%;

    #{$root} {
      &__body {
        position: relative;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: calc(100% - 60px);
        padding: 0 15px 20px;

        background-color: $g-color-light-gray-2;

        overflow: hidden;
      }

      &__body-top {
        position: relative;

        width: 100%;
        padding-top: 15px;

        &::before,
        &::after {
          content: '';

          position: absolute;
          top: 15px;

          width: 20px;
          height: 20px;
        }

        &::before {
          left: 0;

          background-color: $g-color-primary;
          border-bottom-right-radius: 100%;
        }

        &::after {
          right: 0;

          background-color: $g-color-general-dark;
          border-bottom-left-radius: 100%;
        }
      }

      &__body-title {
        margin-bottom: 0;
        padding: 10px 0px 25px;

        text-align: center;
        @include h-text-small;
        color: $g-color-general-dark;

        border-bottom: 1px solid rgba($color: $g-color-general-dark, $alpha: 0.05);

        &--mobile {
          @include h-text-h3;
        }
      }

      &__body-buttons {
        padding: 24px 0;

        text-align: center;

        border-bottom: 1px solid rgba($color: $g-color-general-dark, $alpha: 0.05);
      }

      &__body-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &__body-text {
        @include h-text-small;
        color: rgba($color: $g-color-general-dark, $alpha: 0.5);
      }

      &__body-apps {
        display: flex;
        margin-top: 15px;
        margin-bottom: 30px;
      }

      &__body-app {
        margin-right: 10px;

        cursor: pointer;

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          svg {
            path:first-of-type {
              fill: $g-color-primary;
            }

            path:last-of-type {
              fill: $g-color-white;
            }
          }
        }
      }

      &__button--burger {
        top: 8px;
        left: -2px;

        width: 25px;

        transform: rotate(45deg);
        &::before {
          top: 0;
          left: 0;

          width: 25px;

          transform: rotate(90deg);
        }

        &::after {
          top: -3px;
          left: 9px;

          width: 7px;
          height: 7px;

          border: 2px solid #fff;
          border-radius: 50%;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-header {
    &__top {
      padding: 25px 30px;
    }

    &__title {
      position: absolute;
      top: calc(50% - 20px / 2);
      left: calc(50% - 203px / 2);

      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      @include h-text-small;
      color: rgba($color: $g-color-general-dark, $alpha: 0.5);
    }

    &__burger {
      display: none;
    }

    &__buttons {
      display: flex;
      justify-content: center;
      align-items: center;

      .new-account {
        @include h-text-small;
        padding: 8px 20px;
      }
    }

    &__user {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__user-name {
      margin-right: 10px;

      @include h-text-small-bold;
    }

    &__user-avatar {
      width: 40px;
      height: 40px;
      padding: 8px;

      background-image: url('../../../../assets/images/user-avatar.svg');
      background-repeat: no-repeat;
      background-position: 50% 50%;

      border-radius: 6px;
    }
  }
}
