.iti {
  .iti__country {
    @include h-text-small;
    color: $g-color-general-dark;

    span {
      color: $g-color-general-dark !important;
    }
  }
}
