&--info {
  position: relative;
  display: block;
  padding: 20px;
  padding-left: 70px;
  border: 1px solid $g-color-gray-2;
  border-radius: 10px;
  @include h-general-transition();

  &:first-child {
    @include media-breakpoint-up(md) {
      margin-right: 30px;
    }
  }

  &:hover {
    border: 1px solid rgba($g-color-general-dark, 0.05);
    box-shadow: 0px 10px 60px rgba($g-color-general-dark, 0.1);

    #{$root} {
      &__title {
        color: $g-color-primary;
      }

      &__background {
        opacity: 1;
      }
    }
  }

  #{$root} {
    &__background {
      position: absolute;
      top: 20px;
      left: 20px;
      opacity: 0.5;
      @include h-general-transition();
    }

    &__title {
      font-weight: 800;
      font-size: 22px;
      line-height: 30px;
      color: rgba($g-color-general-dark, 0.85);
      margin-bottom: 10px;
    }

    &__text {
      @include h-text-small;
      color: $g-color-general-dark;
    }

    &__more {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-family: $g-font-family-alt;
      font-weight: bold;
      font-size: 12px;
      line-height: 1.4;
      margin-top: 10px;

      .icon {
        font-size: 10px;
        margin-left: 10px;
      }
    }
  }
}
