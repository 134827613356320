.c-form-field {
  $root: &;

  position: relative;

  label {
    cursor: pointer;
    user-select: none;
    margin: 0;
    display: block;
  }

  input,
  select,
  textarea,
  #{$root}__input {
    position: relative;

    display: block;
    box-sizing: border-box !important;
    width: 100%;
    padding: 16px;

    @include h-text-h5;
    line-height: 22px;
    color: $g-color-general-dark;

    background: none;
    border: 1px solid $g-color-border;
    border-radius: 10px;
    @include h-general-transition(border-color);

    outline: none;
    appearance: none;
    resize: none;

    option {
      color: $g-color-general-dark;
    }

    &::placeholder {
      color: rgba($g-color-general-dark, 0.2);
    }

    &:hover {
      border-color: rgba($g-color-general-dark, 0.3);
    }

    &:focus {
      border-color: $g-color-primary;

      + #{$root}__label {
        color: $g-color-primary;
      }
    }
  }

  textarea {
    min-height: 120px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  &__label {
    display: block;
    font-family: $g-font-family-alt;
    font-size: 12px;
    line-height: 1.4;
    font-weight: bold;
    color: rgba($g-color-general-dark, 0.3);

    @include h-general-transition(color);
    padding: 0 1px;
    background-color: $g-color-white;
    position: absolute;
    top: -8px;
    left: 16px;
    user-select: none;
    border-radius: 3px;
  }

  &__inner {
    position: relative;
  }

  &__message {
    @include h-text-xs-regular;
    color: rgba($g-color-general-dark, 0.5);
    margin-top: 5px;
  }

  &__msg {
    font-family: $g-font-family-alt;
    font-weight: normal;
    font-size: 12px;
    line-height: 1.4;

    position: absolute;
    bottom: -1px;
    left: 16px;
    transform: translateY(100%);

    &.-error {
      color: $g-color-red;
    }

    &.-success {
      color: $g-color-green;
    }
  }

  &.is-error {
    input,
    select,
    textarea {
      border-color: $g-color-red;
      color: $g-color-red;
    }

    #{$root} {
      &__message {
        color: $g-color-red;
      }

      &__label {
        color: $g-color-red !important;
      }
    }
  }

  &__message {
    text-align: center;
  }

  &__confirm-code {
    display: flex;
    justify-content: center;

    input {
      width: 45px;
      height: 55px;
      margin-right: 12px;
      padding: 5px 5px;

      text-align: center;
      @include h-text-small;

      border: 1px solid #e6e6e6;
      border-radius: 5px;

      &::placeholder {
        color: rgba($color: $g-color-general-dark, $alpha: 0.1);
      }

      &:last-child {
        margin-right: 0;
      }

      &:focus {
        box-shadow: 0px 10px 60px rgba(0, 14, 75, 0.1);
        border-color: rgba(0, 14, 75, 0.3);
      }
    }

    &.is-error {
      input {
        border-color: $g-color-red;
      }
    }
  }

  &__checkbox-label {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 32px;
    margin-bottom: 0;
    padding-left: 42px;

    @include h-text-small;
    color: $g-color-general-dark;

    cursor: pointer;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;

      height: 32px;
      width: 32px;

      background-color: $g-color-white;
      border: 1px solid $g-color-border;
      border-radius: 5px;
    }

    &:hover {
      &:before {
        border-color: rgba($g-color-general-dark, 0.3);
      }
    }
  }

  &__checkbox-input:checked ~ &__checkbox-label {
    &::before {
      background-color: $g-color-primary;
    }

    &::after {
      content: '\e939';

      position: absolute;

      top: 10px;
      left: 10px;

      font-family: 'platform500local' !important;
      font-size: 10px;
      color: $g-color-white;
    }
  }

  &__underline-wrapper {
    position: relative;

    display: block;
    width: 100%;
    margin-bottom: 0;
    padding-top: 25px;

    &--workspace {
      &::after {
        content: '.platform500.com';

        position: absolute;
        bottom: 17px;
        right: 0;

        width: 125px;
        height: 20px;

        @include h-text-menu;
        color: $g-color-general-dark;
      }
    }
  }

  &__underline-label-wrapper {
    @include h-text-xs-regular;
    font-weight: 700;
    color: rgba($color: $g-color-general-dark, $alpha: 0.5);

    &::before,
    &::after {
      content: '';

      position: absolute;
      bottom: 0;

      height: 2px;
      width: 0;

      background-color: $g-color-primary;

      transition: width 0.3s;
    }

    &::before {
      left: 50%;
    }

    &::after {
      right: 50%;
    }
  }

  &__underline-label {
    position: absolute;
    top: 0;

    margin-bottom: 0;
  }

  &__underline-input {
    position: relative;

    display: inline-block;
    width: 100%;
    padding-bottom: 10px;

    @include h-text-h3;
    color: $g-color-primary;

    background: none;
    border: none;
    border-bottom: 2px solid rgba($color: $g-color-general-dark, $alpha: 0.2);

    &--workspace {
      padding-right: 130px;
    }

    &--selector {
      color: rgba($color: $g-color-general-dark, $alpha: 0.2);

      cursor: pointer;
    }

    &::placeholder {
      color: rgba($color: $g-color-general-dark, $alpha: 0.2);
    }

    &:focus,
    &:hover {
      color: rgba($color: $g-color-primary, $alpha: 0.2);

      &::placeholder {
        color: rgba($color: $g-color-primary, $alpha: 0.2);
      }
    }

    &.-filled,
    &.ng-valid {
      color: $g-color-primary;
    }

    &.is-error {
      border-color: $g-color-red;

      &::placeholder {
        color: $g-color-red;
      }
    }
  }

  &__underline-input:hover ~ &__underline-label-wrapper,
  &__underline-input:focus ~ &__underline-label-wrapper,
  &__underline-input.-filled ~ &__underline-label-wrapper,
  &__underline-input.ng-valid ~ &__underline-label-wrapper {
    &::before,
    &::after {
      width: 50%;
    }
  }

  &--password {
    #{$root} {
      &__eye {
        position: absolute;
        right: 16px;
        top: 13px;
        font-size: 20px;
        color: rgba($g-color-general-dark, 0.3);
        cursor: pointer;

        &.-active {
          color: $g-color-primary;
        }

        &:hover {
          color: $g-color-primary-hover;
        }
      }
    }

    input {
      padding-right: 52px;
    }
  }

  &__dropdown {
    position: absolute;
    top: calc(100% + 14px);
    left: 0;
    background-color: $g-color-white;
    box-shadow: 0px 20px 100px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 2px 14px;
    white-space: nowrap;
    min-width: 160px;
    max-width: 250px;
    z-index: 1;
    @include h-general-transition;
    visibility: hidden;
    @include fadeOut;

    &.is-opened {
      z-index: 1;
      visibility: visible;
      @include fadeIn;
    }

    .search {
      display: block;

      input {
        padding: 12px 0;
        border: none;
        border-bottom: 1px solid $g-color-primary;
        background: none;
        @include h-text-h5;
        color: $g-color-primary;

        &::placeholder {
          color: $g-color-primary;
          opacity: 0.2;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        @include h-text-h5;
        padding: 12px 0;
        border-bottom: 1px solid rgba($g-color-general-dark, 0.1);
        cursor: pointer;
        color: $g-color-general-dark;
        @include h-general-transition(color);
        @include h-ellipsis-text;
        display: flex;

        .icon {
          margin-right: 10px;
          flex: none;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          content: '';
          width: 20px;
          height: 20px;
          overflow: hidden;
          border-radius: 50%;
        }

        &:hover {
          color: $g-color-primary;
        }

        &:last-child {
          border-bottom: none;
        }

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }

  &__select-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__logo {
    background-color: $g-color-white;
    box-shadow: 0px 5px 40px rgba(0, 14, 75, 0.05);
    border-radius: 6px;
    width: 65px;
    height: 65px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $g-color-white;
    cursor: pointer;
    @include h-general-transition(border-color);

    &:last-child {
      margin-right: 0;
    }

    &:hover,
    &.is-active {
      border-color: $g-color-primary;
    }

    img {
      width: 37px;
      height: 37px;
    }

    &.is-disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.3;
    }
  }

  &--file {
    position: relative;

    input {
      display: none;
    }

    &:hover {
      #{$root} {
        &__file-body {
          border-color: $g-color-primary;
        }

        &__label {
          color: $g-color-primary;
        }
      }
    }

    &.-uploaded {
      #{$root} {
        &__file-body {
          border: 1px solid $g-color-gray-2;
          pointer-events: none;
        }

        &__label {
          color: rgba($g-color-general-dark, 0.3);
        }
      }
    }

    #{$root} {
      &__preview {
        width: 100%;
        display: flex;
      }

      &__image {
        flex: none;
        width: 100px;
        height: 100px;
        padding: 10px;
        border-radius: 10px;
        border: 1px solid rgba($g-color-general-dark, 0.05);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &__preview-info {
        flex-grow: 1;
        font-family: $g-font-family-alt;
        font-size: 12px;
        line-height: 1.4;
      }

      &__preview-title {
        font-weight: bold;
        color: $g-color-green;
        margin-bottom: 12px;
        margin-top: 16px;
      }

      &__data-row {
        display: flex;
      }

      &__key {
        color: rgba($g-color-general-dark, 0.5);
        margin-right: 8px;
      }

      &__text1 {
        @include h-text-small-bold;
        margin-top: 30px;
        margin-bottom: 2px;

        .blue {
          color: $g-color-primary;
        }
      }

      &__text2 {
        font-family: $g-font-family-alt;
        font-size: 12px;
        line-height: 1.4;
        margin-bottom: 15px;
      }

      &__text3 {
        display: inline-flex;
        align-items: center;
        font-family: $g-font-family-alt;
        font-size: 12px;
        line-height: 1.4;
        color: rgba($g-color-general-dark, 0.3);

        .separator {
          width: 4px;
          height: 4px;
          border-radius: 2px;
          margin: 0 8px;
          background-color: rgba($g-color-general-dark, 0.1);
          flex-shrink: 0;
        }
      }

      &__file-body {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px dashed rgba($g-color-primary, 0.25);
        border-radius: 10px;
        padding: 16px;
        @include h-general-transition();
      }

      &__file-remove {
        display: flex;
        position: absolute;
        top: 16px;
        right: 17px;
        cursor: pointer;
        color: $g-color-red;

        &:hover {
          color: $g-color-red-hover;
        }
      }
    }
  }

  &--iconed {
    input {
      padding-left: 44px;
    }

    #{$root}__icon {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: $g-color-primary;
      font-size: 20px;
    }
  }

  &--phone {
    input {
      padding-left: 48px;
    }

    #{$root}__flag {
      position: absolute;
      left: 13px;
      top: 50%;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;

      &.-not-found {
        left: 16px;
        width: 24px;
        height: 24px;
        background-color: $g-color-gray-2;
        border-radius: 12px;
      }
    }
  }

  &--code {
    #{$root}__resend {
      position: absolute;
      right: 14px;
      top: 50%;
      transform: translateY(-50%);
      padding: 2px;
      font-family: $g-font-family-alt;
      font-weight: bold;
      font-size: 12px;
      line-height: 1.4;
    }
  }

  &--domain {
    input {
      padding-right: 145px;
    }

    &::after {
      content: '.platform500.com';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 16px;
      color: rgba($g-color-general-dark, 0.5);
      @include h-text-menu;
      font-weight: 800;
    }
  }

  &--skype {
    input {
      padding-left: 44px;
    }

    &::before {
      content: '\e92d';
      font-family: 'platform500local';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      color: rgba($g-color-primary, 0.75);
      font-size: 20px;
    }
  }

  &--whatsapp {
    input {
      padding-left: 44px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      width: 20px;
      height: 20px;
      background-image: url('../../../images/whatsapp-icon.svg');
    }
  }

  &--telegram {
    input {
      padding-left: 44px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 16px;
      width: 20px;
      height: 20px;
      background-image: url('../../../images/telegram-icon.svg');
    }
  }

  @import 'c-form-field--select';
}

@include media-breakpoint-up(md) {
  .c-form-field {
    &__logo {
      width: 75px;
      height: 75px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .c-form-field {
    $root: &;

    &__underline-text {
      font-size: 26px;
    }

    &__underline-selector {
      font-size: 26px;
    }

    &__logo {
      width: 80px;
      height: 80px;

      img {
        width: 40px;
        height: 40px;
      }
    }
  }
}
