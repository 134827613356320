.c-main-content {
  $root: &;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  margin-top: 60px;

  &__form {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: calc(100vh - 60px);
    margin-top: auto;
    padding-top: 60px;
  }

  &__form--error {
    margin: auto;
    padding: 60px;

    border-radius: 10px;
    box-shadow: 0 0 100px rgba(0, 14, 75, 0.2);
  }

  &__canvas {
    padding-bottom: 40px;
    canvas {
      display: none;
    }

    &--email,
    &--reset,
    &--reset-email,
    &--reset-confirm {
      display: none;
    }
  }

  &--error {
    flex-direction: column;
    padding-top: 60px;

    background-color: rgba($color: $g-color-general-dark, $alpha: 0.03);
  }

  @include media-breakpoint-up(md) {
    display: block;

    &__form {
      min-height: auto;
      padding-bottom: 60px;
    }

    &__canvas {
      canvas {
        display: block;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    min-height: calc(100vh - 80px);
    margin-top: 80px;
    margin-bottom: 0;

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: calc(100vh - 80px);
      margin-top: 0;
      margin-left: 70px;
      padding: 0;
    }

    &__canvas {
      height: auto !important;
      padding-bottom: 0;

      &--wrapper {
        position: relative;

        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: calc(100% - 370px);
        padding: 20px 0;
      }

      &--wrapper-full {
        width: 100%;
      }

      &--email,
      &--reset,
      &--reset-email,
      &--reset-confirm {
        position: absolute;
        top: 0;

        display: block;
      }

      &--email,
      &--reset-email {
        right: 0;
      }

      &--reset {
        left: 0;
      }

      &--reset-confirm {
        left: 0;
        right: 0;
      }

      &--email,
      &--reset,
      &--reset-email {
        @media (max-width: 1700px) {
          max-width: 300px;
          max-height: 270px;
        }

        @media (max-width: 1350px) {
          max-width: 230px;
          max-height: 180px;
        }

        @media (max-width: 1150px) {
          max-width: 180px;
          max-height: 130px;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    &__form {
      margin-top: 0;
      margin-left: 120px;
    }

    &__canvas {
      &--wrapper {
        width: calc(100% - 420px);
      }
    }
  }

  @media (min-resolution: 192dpi) and (min-width: 768px) and (max-width: 1024px) {
    display: block;

    &__form {
      min-height: auto;
      padding-top: 60px;
      padding-bottom: 60px;
    }

    &__canvas {
      height: auto !important;

      &--wrapper {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  &.--register {
    @include media-breakpoint-up(lg) {
      #{$root} {
        &__form {
          margin-right: 120px;
          margin-left: 0;
          order: 2;
        }

        &__input {
          &.is-error {
            border-color: $g-color-red;

            &::placeholder {
              color: $g-color-red;
            }
          }
        }

        &__canvas {
          &--wrapper {
            width: 100%;
            max-width: 1500px;
            height: 100%;

            padding: 0;

            order: 1;
          }
        }
      }
    }
  }
}
