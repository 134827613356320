.l-modal {
  $root: &;
  background-color: rgba($g-color-general-dark, 0.8);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-y: auto;
  z-index: 1050;
  visibility: visible;
  @include h-scrolled;
  @include fadeIn;

  &__modal {
    visibility: visible;
    width: 100%;

    @include fadeInDown;

    &.is-loading {
      display: flex;

      .c-modal {
        margin: auto;
      }

      .c-modal__inner {
        border-radius: 10px;
      }
    }
  }

  &.is-closing {
    @include fadeOut;

    #{$root} {
      &__modal {
        @include fadeOutUp;
      }
    }
  }
}
