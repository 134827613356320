.c-overlay {
  position: relative;

  &::after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-color: rgba($color: $g-color-white, $alpha: 0.5);
  }

  &.c-wizard__content {
    &::after {
      background-color: rgba($color: $g-color-light-gray-2, $alpha: 0.5);
    }
  }

  #chargify-form {
    &::after {
      position: static;
    }
  }
}
