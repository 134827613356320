.c-login {
  $root: &;
  $color-black: #000932;

  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: 40% 60%;
  }

  .signin-bg {
    background-image: url('../../../../assets/images/signin-bg-overlay.svg'), url('../../../../assets/images/signin-bg.png');
    background-repeat: no-repeat;
    background-size: cover, contain;
    background-position: 0, center;
  }

  &__form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 0;
  }

  .c-form {
    &__icon--account {
      margin-bottom: 30px;
    }
    &__title {
      margin: 0;
      margin-bottom: 38px;
    }
    &__separator {
      border-right: 1px solid $g-color-light-primary;
      border-radius: 10px;
      height: 20px;
    }
  }

  .c-form-legend {
    fieldset {
      display: block;
      position: relative;
      padding: 16px 16px 32px 16px;
      border: 1px solid $g-color-light-primary;
      background: linear-gradient(180deg, #ffffff 50%, #f5f9ff 100%);
      line-height: 1;
      color: $color-black;

      &,
      input {
        border-radius: 10px;
      }

      legend {
        position: relative;
        line-height: 0;
        z-index: 2;
        padding: 0px 2px;
        font-size: 12px;
        font-family: $g-font-family-alt;
        color: $g-color-general-dark;
        opacity: 0.5;
        @include h-general-transition();
      }

      input {
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 5px 16px 0px 16px;
        border: none !important;
        z-index: 1;
        background-color: transparent;
        @include h-text-menu;

        &::placeholder {
          opacity: 0.5;
        }

        &:hover + legend,
        &:active + legend,
        &:focus + legend {
          opacity: 1;
        }
      }

      input:-webkit-autofill,
      input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
      }

      button.eye {
        @include h-general-transition();
        &,
        &:hover,
        &:active,
        &:focus {
          border: none;
          outline: none;
          line-height: 1;
          background: transparent;
          position: absolute;
          right: 16px;
          z-index: 3;
          cursor: pointer;
          font-size: 16px;
          color: $g-color-general-dark;
          opacity: 0.5;
        }
        &:hover,
        &:focus,
        &.-active {
          opacity: 1;
        }
      }
    }
  }
}
