@font-face {
  font-family: 'platform500local';
  src: url('/assets/fonts/font-icons/fonts/platform500.eot?ab1o9');
  src: url('/assets/fonts/font-icons/fonts/platform500.eot?ab1o9#iefix') format('embedded-opentype'),
    url('/assets/fonts/font-icons/fonts/platform500.ttf?ab1o9') format('truetype'),
    url('/assets/fonts/font-icons/fonts/platform500.woff?ab1o9') format('woff'),
    url('/assets/fonts/font-icons/fonts/platform500.svg?ab1o9#platform500') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'platform500local' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-1:before {
  content: '\e900';
}
.icon-arrow-2:before {
  content: '\e901';
}
.icon-arrow-3:before {
  content: '\e902';
}
.icon-book:before {
  content: '\e903';
}
.icon-check:before {
  content: '\e904';
}
.icon-design:before {
  content: '\e905';
}
.icon-development:before {
  content: '\e906';
}
.icon-download:before {
  content: '\e907';
}
.icon-email:before {
  content: '\e908';
}
.icon-facebook:before {
  content: '\e909';
}
.icon-icon-1:before {
  content: '\e90a';
}
.icon-icon-2:before {
  content: '\e90b';
}
.icon-icon-3:before {
  content: '\e90c';
}
.icon-icon-4:before {
  content: '\e90d';
}
.icon-icon-5:before {
  content: '\e90e';
}
.icon-icon-6:before {
  content: '\e90f';
}
.icon-icon-7:before {
  content: '\e910';
}
.icon-icon-8:before {
  content: '\e911';
}
.icon-icon-9:before {
  content: '\e912';
}
.icon-icon-10:before {
  content: '\e913';
}
.icon-icon-11:before {
  content: '\e914';
}
.icon-icon-12:before {
  content: '\e915';
}
.icon-icon-13:before {
  content: '\e916';
}
.icon-icon-14:before {
  content: '\e917';
}
.icon-icon-15:before {
  content: '\e918';
}
.icon-icon-16:before {
  content: '\e919';
}
.icon-icon-17:before {
  content: '\e91a';
}
.icon-icon-18:before {
  content: '\e91b';
}
.icon-icon-19:before {
  content: '\e91c';
}
.icon-icon-20:before {
  content: '\e91d';
}
.icon-icon-21:before {
  content: '\e91e';
}
.icon-icon-22:before {
  content: '\e91f';
}
.icon-icon-23:before {
  content: '\e920';
}
.icon-icon-24:before {
  content: '\e921';
}
.icon-icon-25:before {
  content: '\e922';
}
.icon-icon-26:before {
  content: '\e923';
}
.icon-icon-27:before {
  content: '\e924';
}
.icon-information:before {
  content: '\e925';
}
.icon-linkedin:before {
  content: '\e926';
}
.icon-location:before {
  content: '\e927';
}
.icon-officce:before {
  content: '\e928';
}
.icon-phone:before {
  content: '\e929';
}
.icon-plane:before {
  content: '\e92a';
}
.icon-qa:before {
  content: '\e92b';
}
.icon-search:before {
  content: '\e92c';
}
.icon-skype:before {
  content: '\e92d';
}
.icon-sms:before {
  content: '\e92e';
}
.icon-star:before {
  content: '\e92f';
}
.icon-support:before {
  content: '\e930';
}
.icon-twitter:before {
  content: '\e931';
}
.icon-upload:before {
  content: '\e932';
}
.icon-youtube:before {
  content: '\e933';
}
.icon-android:before {
  content: '\e934';
}
.icon-apple:before {
  content: '\e935';
}
.icon-arrow-4:before {
  content: '\e936';
}
.icon-plus:before {
  content: '\e937';
}
.icon-check-2:before {
  content: '\e939';
}
.icon-instagram:before {
  content: '\e93a';
}
.icon-account:before {
  content: '\e93b';
}
.icon-logout:before {
  content: '\e93c';
}
.icon-logo-d2:before {
  content: '\e93d';
}
.icon-logo-d1:before {
  content: '\e93e';
}
.icon-plus-2:before {
  content: '\e93f';
}
.icon-bin:before {
  content: '\e940';
}
.icon-close:before {
  content: '\e941';
}
.icon-edit:before {
  content: '\e942';
}
.icon-eye:before {
  content: '\e943';
}
.icon-hello:before {
  content: '\e944';
}
.icon-info:before {
  content: '\e945';
}
.icon-lists:before {
  content: '\e946';
}
.icon-long-line:before {
  content: '\e947';
}
.icon-minus:before {
  content: '\e948';
}
.icon-play-outline:before {
  content: '\e949';
}
.icon-remove:before {
  content: '\e94a';
}
.icon-search-2:before {
  content: '\e94b';
}
