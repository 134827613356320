@mixin h-text-h1 {
  font-weight: 800;
  font-size: 36px;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 58px;
  }
}

@mixin h-text-h2 {
  font-weight: 800;
  font-size: 30px;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 38px;
  }
}

@mixin h-text-h3 {
  font-weight: 800;
  font-size: 22px;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 26px;
  }
}

@mixin h-text-h4 {
  font-weight: 800;
  font-size: 18px;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 22px;
  }
}

@mixin h-text-h5 {
  font-weight: 800;
  font-size: 16px;
  line-height: 1.3;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

@mixin h-text-subtitle {
  font-family: $g-font-family-alt;
  font-weight: normal;
  font-size: 20px;
  line-height: 1.7;

  @include media-breakpoint-up(md) {
    font-size: 24px;
  }
}

@mixin h-text-regular {
  font-family: $g-font-family-alt;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.7;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

@mixin h-text-bold {
  font-family: $g-font-family-alt;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.7;

  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

@mixin h-text-small {
  font-family: $g-font-family-alt;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
}

@mixin h-text-small-bold {
  font-family: $g-font-family-alt;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.4;
}

@mixin h-text-menu {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
}

@mixin h-text-menu-2 {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

@mixin h-text-button {
  font-weight: normal;
  font-size: 13px;
  line-height: 1.5;
}

@mixin h-text-xs-regular {
  font-weight: normal;
  font-size: 12px;
  line-height: 1.4;
}

@mixin h-text-xs-bold {
  font-weight: bold;
  font-size: 12px;
  line-height: 1.4;
}
