.c-notification {
  $root: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  margin: 0 auto;
  padding: 10px 20px;

  @include h-text-small;

  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 10px 60px rgba(0, 14, 75, 0.1);

  @include fadeInUp;

  &__close {
    width: 22px;
    height: 12px;

    opacity: 0.5;

    background-image: url('../../../../assets/images/close.svg');
    background-position: 0 0;
    background-repeat: no-repeat;

    cursor: pointer;

    @include h-general-transition(opacity);

    &:hover {
      opacity: 1;
    }
  }

  &.is-success {
    #{$root} {
      &__content {
        background-image: url('../../../../assets/images/success.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
      }
    }
  }

  &.is-info {
    #{$root} {
      &__content {
        background-image: url('../../../../assets/images/info.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
      }
    }
  }

  &.is-error {
    #{$root} {
      &__content {
        background-image: url('../../../../assets/images/error.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
      }
    }
  }

  &__content {
    padding-left: 40px;
    padding-bottom: 3px;
    margin-right: 10px;

    @include h-ellipsis-text;
    white-space: normal;
  }

  &__text {
    margin-top: 10px;

    @include h-text-xs-regular;
  }

  &.is-closing {
    @include fadeOutDown;
  }
}

@include media-breakpoint-up(md) {
  .c-notification {
    min-width: 450px;
  }
}
