.c-profile {
  $root: &;

  &--mini {
    #{$root} {
      &__avatar {
        width: 50px;
        height: 50px;
        border-radius: 6px;
        background-color: $g-color-general-dark;
        color: $g-color-white;
        margin-right: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }

      &__title {
        @include h-ellipsis-text;
        @include h-text-h4;

        &.--dark {
          color: $g-color-general-dark;
        }
      }

      &__subtitle {
        @include h-text-xs-regular;
        @include h-ellipsis-text;
        opacity: 0.5;
        margin-top: 2px;
      }

      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
      }

      &__navigation {
        @include h-text-menu;
        margin-bottom: -15px;

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            a,
            .c-link {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 15px 0;
              border-bottom: 1px solid $g-color-border-2;
              color: $g-color-general-dark;
              @include h-general-transition;

              .icon {
                width: 15px;
                font-size: 15px;
                text-align: center;
                margin-right: 10px;
                color: $g-color-primary;
              }

              &:hover {
                color: $g-color-primary;
              }
            }

            &:last-child {
              a,
              .c-link {
                border-bottom: none;
              }
            }

            &.logout {
              a,
              .c-link {
                color: $g-color-red;

                .icon {
                  color: $g-color-red;
                }

                &:hover {
                  color: $g-color-red-hover;

                  .icon {
                    color: $g-color-red-hover;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
