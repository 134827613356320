$g-color-primary: #0061ff;
$g-color-secondary: #000932;
$g-color-primary-hover: #004de2;
$g-color-general-dark: #000e4b;
$g-color-content-color: rgba($g-color-general-dark, 0.85);
$g-color-white: #ffffff;
$g-color-green: #20b506;
$g-color-red: #fc5151;
$g-color-red-hover: #f74141;
$g-color-light-red: #ffeeee;
$g-color-gray: rgba($g-color-general-dark, 0.03);
$g-color-light-yellow: #ffdda6;
$g-color-gray-2: #e6e6e6;
$g-color-gray-3: rgba($g-color-general-dark, 0.5);
$g-color-light-primary: #eef5ff;
$g-color-light-gray: #f4f5f7;
$g-color-dark-gray: #c9c9c9;
$g-color-light-gray-2: #f7f8fa;
$g-color-light-gray-2-dark: #f7f7f7;
$g-color-border: #eaeaea;
$g-color-border-2: rgba($g-color-general-dark, 0.05);
$g-color-grey-stroke: #e6e6e6;
$g-color-text-brand: #0F5EF7;
$g-color-tag-text-green: #2e6b22;
$g-color-tag-text-yellow: #8C5F1F;
$g-color-tag-bg-green: #ecf5ea;
$g-color-tag-bg-yellow: #FFF7EB;
$g-color-tag-icon-green: #3B892C;
$g-color-tag-icon-yellow: #E89D33;
$g-color-border-tertiary: #DEDFE4;
$g-color-light-blue: #eef5ff;

// Global Values
$g-value-border-radius: 4px;
$g-value-animation-duration: 0.2s;
$g-value-animation-function: 'ease';
$g-value-transition: $g-value-animation-duration $g-value-animation-function;

// Global Fonts

$g-font-family-alt: 'Open Sans', sans-serif;

// Config for @animatewithsass

$durationDefault: $g-value-animation-duration;
$functionDefault: $g-value-animation-function;

// Config for @bootstrap

$grid-breakpoints: (
  xs: 0,
  sm: 375px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
);
