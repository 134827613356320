&--autorized {
  display: flex;
  justify-content: center;

  #{$root} {
    &__header {
      #{$root}__title {
        margin-bottom: 0;
      }
    }
    &__body {
      align-items: unset;
    }
    &__row {
      width: unset;
    }
  }
}
