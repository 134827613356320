.-mb-30 {
  margin-bottom: -30px !important;
}

$steps: 15;

@mixin mt-x-list {
  @for $i from 1 through $steps {
    .mt-#{$i * 2} {
      margin-top: $i * 2px !important;
    }
  }
}

@mixin mr-x-list {
  @for $i from 1 through $steps {
    .mr-#{$i * 2} {
      margin-right: $i * 2px !important;
    }
  }
}

@mixin mb-x-list {
  @for $i from 1 through $steps {
    .mb-#{$i * 2} {
      margin-bottom: $i * 2px !important;
    }
  }
}

@mixin ml-x-list {
  @for $i from 1 through $steps {
    .ml-#{$i * 2} {
      margin-left: $i * 2px !important;
    }
  }
}

@include mt-x-list;
@include mr-x-list;
@include mb-x-list;
@include ml-x-list;
