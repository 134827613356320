.c-tooltip {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;

  display: block;
  padding: 4px;

  font-size: 11px;
  line-height: 12px;
  color: $g-color-white;
  white-space: nowrap;

  background-color: $g-color-red;
  border-radius: 3px;

  &:after {
    content: '';

    position: absolute;
    top: -3px;
    left: 50%;
    transform: translateX(-50%);

    display: inline-block;
    width: 0;
    height: 0;

    border-style: solid;
    border-width: 0 3px 3px 3px;
    border-color: transparent transparent $g-color-red transparent;
  }
}
