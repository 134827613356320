.l-notifications {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  padding: 0 15px;
  top: 60px;
  z-index: 2000;

  @include media-breakpoint-up(lg) {
    top: 0;
  }

  &__item {
    display: flex;
    margin-bottom: 10px;
  }
}
