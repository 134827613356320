.agreement-card:not(:last-child) {
  margin-bottom: 0.75rem;
}
.agreement-card {
  margin: 0 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
  padding: 16px 12px;
  border-radius: 5px;
  border: 1px solid $g-color-grey-stroke;

  .left-section {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .agreement-title {
    color: $g-color-text-brand;
    font-weight: bold;
    margin: 0;
  }

  .status {
    @include font-headline-5;
    display: flex;
    align-items: center;
    background-color: $g-color-tag-bg-green;
    padding: 0.15rem 0.6rem;
    border-radius: $g-value-border-radius;
    margin-right: 1rem;

    &.accepted {
      color: $g-color-tag-text-green;
      background-color: $g-color-tag-bg-green;
    }

    &.not-accepted {
      color: $g-color-tag-text-yellow;
      background-color: $g-color-tag-bg-yellow;
    }

    .status-icon {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      margin-right: 0.4rem; //

      &.accepted {
        background-color: $g-color-tag-icon-green;
      }
      &.not-accepted {
        background-color: $g-color-tag-icon-yellow;
      }
    }
  }

  span.icon-accepted {
    color: $g-color-tag-icon-green;
  }

  .accept-text-button {
    color: $g-color-secondary;
    font-weight: bold;
    padding: 6px 16px;
    border: 1px solid $g-color-border-tertiary;
    border-radius: $g-value-border-radius;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background-color: #f0f1f3;
    }
  }
}
