.c-tariffs {
  &__family-cards {
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
    overflow-x: auto;
    width: 100%;

    @media (min-width: 810px) {
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    @include media-breakpoint-up(lg) {
      overflow: visible;
    }
  }

  &__family-card {
    width: 240px;
    margin-right: 30px;
    flex-shrink: 0;
  }

  &__payment-cards {
    display: block;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 380px;
    }

    @include media-breakpoint-up(lg) {
      width: 780px;
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -20px;
    }
  }

  &__payment-card {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      width: 240px;
      margin-right: 30px;
    }
  }

  &__about-link {
    font-family: $g-font-family-alt;
    font-weight: 800;
    font-size: 12px;
    line-height: 1.4;

    display: inline-flex;
    align-items: center;

    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    .icon {
      font-size: 12px;
      margin-left: 10px;
    }
  }
}
