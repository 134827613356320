&--select {
  #{$root}__input {
    padding: 15px 16px;
    background-color: $g-color-white;
  }

  .ng-input {
    input {
      border-radius: 0px;
    }
  }

  .ng-select .ng-has-value .ng-placeholder {
    display: none;
  }

  .ng-placeholder {
    color: rgba($g-color-general-dark, 0.2);
    line-height: 24px;
  }

  .ng-dropdown-panel {
    left: 0;
    top: 56px;
    padding-top: 4px;
  }

  .ng-select-opened {
    border-color: $g-color-primary !important;

    + #{$root}__label {
      color: $g-color-primary;
    }

    .ng-arrow-wrapper {
      &:before {
        content: '\eb44';
      }
    }
  }

  .ng-arrow-wrapper {
    position: absolute;
    font-size: 6px;
    right: 0;
    top: 50%;

    &:before {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 8px;
      color: $g-color-general-dark;
      content: '\eb41';
      font-family: 'platform500';
      display: block;
    }
  }

  .ng-value-container {
    padding-right: 10px !important;

    input {
      padding: 0;
      cursor: pointer !important;
    }
  }

  .ng-value {
    display: inline-flex;
    align-items: center;
  }

  .ng-value-label {
    display: inline-flex;
    align-items: center;
    line-height: 24px;

    img {
      margin-right: 8px;
      object-fit: fill;
    }
  }

  .ng-option img {
    margin-right: 8px;
    object-fit: fill;
  }

  .ng-dropdown-panel-items {
    max-height: 235px !important;
    background-color: $g-color-white;
    border: 1px solid rgba($g-color-general-dark, 0.2);
    border-radius: 10px;
    box-shadow: 0 10px 40px rgba($g-color-general-dark, 0.2);

    .ng-option {
      white-space: nowrap;

      .ng-option-label {
        display: flex;
        align-items: center;
        padding: 10px 16px;
        margin: 2px 0;
        min-width: 120px;
        font-size: 16px;
        line-height: 22px;
        @include h-general-transition(background-color, color);

        &:hover {
          background-color: $g-color-light-primary;
          color: $g-color-primary;
        }
      }

      &:after {
        display: block;
        content: '';
        width: 100%;
        border-bottom: 1px solid rgba($g-color-general-dark, 0.1);
      }

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 10px;

        &:after {
          display: none;
        }
      }

      &.ng-option-selected {
        cursor: default;
        pointer-events: none;

        .ng-option-label {
          color: $g-color-gray-2;
          pointer-events: none;
        }

        img {
          opacity: 0.25;
        }
      }
    }
  }

  &.is-error {
    #{$root}__input {
      border-color: $g-color-red !important;
    }

    .ng-input,
    .ng-value {
      color: $g-color-red;
    }
  }
}
