.c-steps-bar {
  $root: &;

  display: flex;
  justify-content: center;

  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-weight: bold;
    color: $g-color-general-dark;
    border: 1px solid rgba($g-color-general-dark, 0.3);
    border-radius: 50%;
  }

  &__title {
    display: none;
    @include h-text-small-bold;
    color: $g-color-primary;
    text-transform: uppercase;
    white-space: nowrap;
  }

  &__short {
    @include h-text-small-bold;
  }

  &__step {
    display: flex;
    align-items: center;

    &:after {
      content: '';
      width: 20px;
      height: 1px;
      margin: 0 10px;
      flex-shrink: 0;
      background-color: rgba($g-color-general-dark, 0.1);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    &.is-finished {
      #{$root} {
        &__count {
          color: $g-color-white;
          border-color: $g-color-green;
          background-color: $g-color-green;
        }
      }
    }

    &.is-active {
      display: flex;

      #{$root} {
        &__count {
          color: $g-color-primary;
          border-width: 2px;
          border-color: $g-color-primary;
        }

        &__title {
          margin-left: 10px;
          display: block;
        }
      }
    }

    &.-last {
      #{$root} {
        &__count {
          font-size: 13px;
        }
      }
    }
  }
}
