&--payment-type {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: $g-color-light-gray-2;
  border: 1px solid rgba($g-color-general-dark, 0.05);
  padding: 10px 20px;
  cursor: pointer;
  @include h-general-transition();

  &:last-child,
  &:nth-of-type(3n) {
    margin-right: 0;
  }

  &:hover {
    background-color: $g-color-white;
    box-shadow: 0px 10px 60px rgba($g-color-general-dark, 0.1);
  }

  #{$root} {
    &__title {
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      @include h-general-transition();
    }

    &__image {
      width: 50px;
      height: 50px;
      object-fit: contain;
      margin-right: 10px;
    }

    &__checkbox {
      width: 24px;
      height: 24px;
      margin-left: auto;
      border: 1px solid rgba($g-color-general-dark, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: transparent;
      font-size: 10px;
      flex: none;
    }

    &__meta {
      display: none;
      position: absolute;
      top: 2px;
      right: 2px;
      padding: 3px 8px;
      font-size: 11px;
      line-height: 16px;
      font-weight: 700;
      color: rgba($g-color-general-dark, 0.5);
      background-color: rgba($g-color-general-dark, 0.05);
      border-top-right-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  &.is-active {
    border: 1px solid rgba($g-color-general-dark, 0.1);
    background-color: $g-color-white;
    box-shadow: none;

    #{$root} {
      &__checkbox {
        background-color: $g-color-primary;
        border-color: $g-color-primary;
        color: $g-color-white;
      }
    }
  }

  &.is-active,
  &:hover {
    #{$root} {
      &__title {
        color: $g-color-primary;
      }
    }
  }

  &.is-disabled {
    pointer-events: none;
    background-color: $g-color-white;

    #{$root} {
      &__meta {
        display: block;
      }

      &__checkbox {
        display: none;
      }

      &__title {
        color: rba($g-color-general-dark, 0.2);
      }

      &__image {
        filter: grayscale(100%);
      }
    }
  }
}
